<template>
  <div :class="isInter ? 'newBg' : ''">
    <div :class="isInter ? 'modifywidth' : ''">
      <screenTemplate1
        ref="screenTemplate1"
        :isInter="isInter"
        v-if="template_type == 1"
      />
      <screenTemplate2
        ref="screenTemplate2"
        :isInter="isInter"
        v-if="template_type == 2"
      />
      <div class="closeScreen" @click="fGoIntegretedService" v-if="isInter">
        x
      </div>
    </div>
  </div>
</template>
<script>
import screenTemplate1 from "./other/index.vue";
import screenTemplate2 from "./newScreen/index.vue";
import { detectZoom } from "@/utils/detectZoom.js";

import { screenTemplateServe } from "./api";
export default {
  name: "",
  components: { screenTemplate1, screenTemplate2 },
  mixins: [],
  props: {},
  data() {
    return {
      template_type: 1,
      isInter: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    var m = detectZoom();
    //判断屏幕是否为
    if (window.screen.width * window.devicePixelRatio >= 3840) {
      document.body.style.zoom = 100 / Number(m);
    }
    this.isInter = this.$route.query.isInter ? true : false;
    sessionStorage.setItem("uuid", this.$route.query.uuid);
    if (window.android) {
      var qs = JSON.parse(window.android.getBindInfo());
      this.uuid = qs["uuid"];
      sessionStorage.setItem("uuid", this.uuid);
    }
    this.getScreenTemplate();
    this.timer = setInterval(() => {
      this.getScreenTemplate();
    }, 300000);
  },
  destoryed() {
    this.clearInterval(this.timer);
  },
  methods: {
    fGoIntegretedService() {
      this.$router.back();
    },
    async getScreenTemplate() {
      const { code, data } = await screenTemplateServe({
        action: "get.template.type",
      });

      if (code == 0) {
        this.template_type = data.template_type;
        if (data.template_type == 1) {
          this.$refs.screenTemplate1.init();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.newBg {
  width: 100%;
  height: 100%;
  background: url("https://www.chuansmart.com/storage/screen/bg33.jpg")
    no-repeat;
  background-size: 100% 100%;
}
.modifywidth {
  width: 1920px;
  height: 1080px;
  margin: 0 auto;
}
.closeScreen {
  position: absolute;
  top: 40%;
  left: 10px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  font-size: 48px;
  overflow: hidden;
  color: #ffffff;
  background-color: rgba(180, 190, 190, 0.5);
  box-shadow: -1px 7px 10px #000000;
  text-align: center;
  z-index: 9999;
  cursor: pointer;
}
</style>
